@media screen and (min-width:769px) {
    .desktop-menu{
        display: unset;
        visibility:unset;
    }
    .mobile-menu{
        display: none;
        visibility: hidden
    }
}

@media screen and (max-width:768px) {
    .desktop-menu{
        display: none;
        visibility: hidden
    }
    .mobile-menu{
        display: unset;
        visibility:unset;
    }
}