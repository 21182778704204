@import "button.css";
@import "input-styling.css";
@import "pagination.css";
@import "property.css";
@import "navigation.css";
@import "account.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
  scroll-behavior: smooth;
  margin: 0 !important;
  padding: 0 !important;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100% !important;
  overflow-x: hidden !important;
}
a {
  text-decoration: none;
}

.link div:hover {
  color: #7cb73f !important;
}

:focus-visible {
  outline: none !important;
}

.menu {
  list-style: none;
  margin: auto 0px !important;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: inline-flex;
}

.menu-list {
  display: flex;
  margin: auto 40px 0px 0px !important;
}

.menu-list a:hover {
  color: #7cb73f !important;
}

.text-center {
  text-align: center;
}

.no-border {
  border: none !important;
}

hr.style-one {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #1e1e1e, #ccc);
}

.room-confirm-edit {
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.3s;
}

.room-confirm-edit:hover {
  background-color: #7cb73f40;
}
