.previous_page{
    margin-right: 10px;
    margin-left: 10px;
}
   
.next_page{
    margin-right: 10px;
    margin-left: 10px;
}
  
li.inactive_number a{
    padding:8px 14px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius:5px;
    color:#6a6a6a !important;
    margin: 10px;
    margin-top:-5px;
    font-size:14px;
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 0px 20px -3px rgba(0,0,0,0.2);
}
  
li.active_number a{
    color:#fff !important;
    background: #7db73f;
    box-shadow: none;
}
  
.pagination{
    margin-bottom: 30px;
    display: flex;
    list-style: none;
    justify-content: center;
}

.pagination li a{
    cursor: pointer;
    margin-top:-5px;
}


