.property-card-container {
  width: 290px;
  margin: 0px 15px 20px;
}
.property-card {
  position: relative;
  z-index: 3 !important;
  background: rgb(255, 255, 255);
  width: 100%;
  height: 97%;
  border-radius: 5px;
  border: 1px solid rgb(246, 246, 246);
  padding-bottom: 20px;
}

.property-card-image {
  object-fit: cover;
  border-bottom: 1px solid rgb(246, 246, 246);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: 100%;
  height: 200px;
  cursor: pointer;
}

.property-card-body {
  padding: 0px 20px;
  position: relative;
}

.property-card-favourite {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  background: rgb(246, 246, 246);
  display: flex;
  position: absolute;
  top: 0px;
  right: 20px;
  cursor: pointer;
  z-index: 2;
  pointer-events: all;
}
.property-card-rent-container {
  display: flex;
  margin: 10px 0px -15px;
}

.property-card-rent-value {
  font-size: 20px;
  font-weight: 700;
  color: rgb(124, 183, 63);
}

.property-card-rent-text {
  margin-top: 7px;
  margin-left: 3px;
  font-size: 13px;
  color: rgb(106, 106, 106);
  font-weight: 500;
}

.property-card-location {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.property-card-address {
  font-weight: 500;
  font-size: 14px;
  color: rgb(106, 106, 106);
}

.property-card-divider {
  height: 1px;
  width: 100%;
  background: rgb(234, 231, 231);
  margin: 20px 0px 15px;
}

.property-card-features-container {
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: auto;
  cursor: pointer;
}

.property-card-features-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 5px;
}

.property-card-features-item:last-child {
  margin-right: 0px;
}

.property-card-features-item-title {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 600;
  color: rgb(106, 106, 106);
}

.tri {
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-right: 13px solid #557e2b;
  /* Added lines */
  top: -0.2rem;
  left: -13px;
  z-index: -1;
}

.tri.dark {
  border-right: 13px solid #555;
}

.square.dark {
  background: #212121;
}

.square {
  position: absolute;
  top: -0.9rem;
  left: -0.85rem;
  padding: 5px 15px;
  background: #7cb73f;
  color: white;
  text-align: center;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
  z-index: 2;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
}

/* Property Details Page*/

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .property-card-container {
    width: 325px;
    margin: 0px 20px 20px;
  }

  .advance-search-bar {
    display: none !important;
  }

  .search-bar {
    flex-direction: column;
    align-items: center !important;
  }
  .search-bar h1 {
    margin-bottom: 10px !important;
  }
  .search-bar-input-container {
    width: 100% !important;
    max-width: 300px;
  }

  /* Property Details Page*/
  .property-details-inner-container {
    padding: 0px 20px;
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .property-card-container {
    width: 100%;
    /* padding: 0px 15px 20px; */
  }

  .advance-search-bar {
    display: none;
  }

  .advance-search-bar {
    display: none !important;
  }

  .search-bar {
    flex-direction: column;
    align-items: center !important;
  }
  .search-bar h1 {
    margin-bottom: 10px !important;
    font-size: 30px !important;
  }
  .search-bar-input-container {
    width: 100% !important;
  }
}
