.account-main-container {
  padding: 120px 0 100px;
}

.profile-banner-container {
  margin: auto auto 150px;
  width: 100%;
  height: 240px;
  border-radius: 5px;
  position: relative;
}

.profile-banner-container.tenant {
  background: url(https://images.unsplash.com/photo-1560185007-5f0bb1866cab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)
    center -250px / cover rgb(245, 245, 245);
}

.profile-banner-container.landlord {
  background: url(https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)
    center center / cover rgb(245, 245, 245);
}

.profile-summary-container {
  width: 100%;
  position: absolute;
  bottom: -80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile-picture {
  background-position: center center;
  background-size: cover;
  box-shadow: rgb(0 0 0 / 40%) 0px 5px 20px -3px;
  border: 2px solid rgb(255, 255, 255);
  height: 100px;
  width: 100px;
  background-color: rgb(245, 245, 245);
  border-radius: 50%;
}

.user-details-summary-container {
  margin: 25px 0px 0px 15px;
}

.account-tab-icon {
  display: none;
}
.account-tab-title {
  display: initial;
}

.add-home-icon.home {
  display: none;
}
.add-home-icon.plus {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

@media screen and (max-width: 992px) {
  .account-main-container {
    padding: 80px 0 100px;
  }
  .profile-banner-container {
    border-radius: 0px;
  }
}

@media screen and (max-width: 767px) {
  .profile-banner-container.tenant {
    background: url(https://images.unsplash.com/photo-1560185007-5f0bb1866cab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)
      center / cover rgb(245, 245, 245);
  }

  .profile-banner-container.landlord {
    background: url(https://images.unsplash.com/photo-1516156008625-3a9d6067fab5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)
      center center / cover rgb(245, 245, 245);
  }

  .account-main-container {
    padding: 80px 0 100px;
  }
  .profile-banner-container {
    border-radius: 0px;
    height: 100px;
    margin: auto auto 100px;
  }

  .profile-picture {
    height: 80px;
    width: 80px;
  }
  .user-details-summary-container {
    margin: 30px 0px 0px 15px;
  }
  .profile-summary-container {
    bottom: -65px;
  }
  .account-tab-title {
    display: none;
  }
  .account-tab-icon {
    display: initial;
  }
  .add-home-label {
    display: none;
  }
  .add-home-icon.home {
    display: initial;
  }
  .add-home-icon.plus {
    height: 15px;
    width: 15px;
    margin-right: 0px;
  }
}
