input,
select {
  font-family: "Plus Jakarta Sans", sans-serif !important;
  /* box-sizing: border-box; */
  -webkit-appearance: none !important;
}
::placeholder {
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

.custom-input {
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px 5px 5px 5px;
  min-height: 40px;
  width: 100% !important;
  font-family: "Plus Jakarta Sans", Sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 0 1rem;
  /* box-sizing: border-box; */
  margin-bottom: 20px;
}

.custom-input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.rent-card-input input[type="date"] {
  position: relative;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.rent-card-input input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
  cursor: pointer;
}

.rent-card-input input[type="date"] {
  color: #7db73e;
  background: none;
  display: block;
  font-family: "FontAwesome";
  content: "\f078";
  width: 15px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 6px;
  cursor: pointer;
}

/* Search Bar Styling */

input.search-bar-input,
select.search-bar-input {
  font-weight: 700 !important;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  width: 90%;
  border: none;
  color: #212121;
}

select.search-bar-input {
  margin-left: -3px;
  font-family: "Plus Jakarta Sans", sans-serif !important;
}

/* Search Bar Styling End */

/* .custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Select some files';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  margin-right: -80px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none !important;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}
 */

input::file-selector-button {
  font-weight: bold;
  color: #fff;
  padding: 12px 18px 12px 40px;
  border: none;
  background-color: #7db73e;
  border-radius: 3px;
}

.input-error {
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: -15px;
}
